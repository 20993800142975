import { newFrontEnd, coa, stu } from '@/config/origin';
import useMeStore from '@/features/auth/useMeStoreAdapter';
import { tokenStorage } from '@/features/auth/token';
import { el } from 'date-fns/locale';

const meStore = useMeStore();

export const frontEndUrl = (feType, role) => {
  if (location.port) return '';
  feType = +feType;

  let path = getPath(feType);
  path += path.startsWith('?') ? '&' : '?';
  path += '_tk=' + tokenStorage();

  if (feType === 0 && newFrontEnd === location.origin) {
    return '';
  }

  if (feType === 1) {
    if (newFrontEnd !== location.origin) return newFrontEnd + path;
    return '';
  }

  if (/\/agent/i.test(location.pathname)) return '';

  if (/\/code/i.test(location.hash)) {
    role = 1;
  }
  if (/\/(c_bind|c_backregister)/i.test(location.hash)) {
    role = 2;
  }

  let oldOrigin = api(role);
  if (oldOrigin !== location.origin) return oldOrigin + path;

  return '';
};

export const api = (role) => {
  role = +role;
  if (!role) role = getRole();
  if (role === 2 || (!role && coa.api.includes(location.hostname))) {
    return coa.api;
  }
  return stu.api;
};

const getRole = () => {
  return meStore.me?.user_type;
};

const getPath = (feType) => {
  let { pathname = '', hash = '' } = location;
  // const search = location.href.match(/\w+=\w+/g).join('&');

  if (feType === 1) {
    if (pathname.includes('/agent')) {
      if (!(hash.includes('#/e_examine') || hash.includes('#/agentCheck'))) {
        return '/#/agent';
      } else {
        return '/#/agentCheck';
      }
    }
  } else if (feType === 2) {
    if (hash.includes('#/agent')) {
      if (!(hash.includes('#/e_examine') || hash.includes('#/agentCheck'))) {
        return '/agent.html';
      } else {
        return '/agent.html#/e_examine';
      }
    }
  }
  return pathname + hash.replace(/\?$/, '');
};
