import pageLoadProgress from '@/features/common/components/pageLoadProgress.vue';
import weixinAuthorizeUserInfoMask from '@/components/weixinAuthorizeUserInfoMask';
import integralSignMask from '@/components/integralSignMask';
import TabBar from '@/components/TabBar';
export default {
  name: 'App',
  components: {
    TabBar,
    pageLoadProgress,
    weixinAuthorizeUserInfoMask,
    integralSignMask
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  created() {},
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState == 'hidden') {
        if (this.$route.name == 'Index' || this.$route.name == 'Lession' || this.$route.name == 'Cash' || this.$route.name == 'User') {
          this.reload();
        }
      }
    });
  },
  methods: {
    wxError(e) {
      alert(e.detail);
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  }
};