var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('van-cell', {
    attrs: {
      "center": "",
      "title": "隐藏分享个人信息"
    },
    scopedSlots: _vm._u([{
      key: "right-icon",
      fn: function () {
        return [_c('van-switch', {
          attrs: {
            "value": _vm.meStore.shareHiddenInfo,
            "model-value": _vm.meStore.shareHiddenInfo,
            "size": "24"
          },
          on: {
            "input": _vm.meStore.getOrSetShareHiddenInfo,
            "update:model-value": _vm.meStore.getOrSetShareHiddenInfo
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };